var $ = require("jquery");

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

/* ====================== SERVICES SLIDER ======================  */
var testimonialsSlider = new Swiper('.testimonials__slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,

    autoplay: {
        delay: 7000,
    },
});

var projectsSlider = new Swiper('.hp-projects__slider', {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 20,
    loop: true,

    breakpoints: {
        768: {
            spaceBetween: 30,
        },
    },
});