import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

jQuery(document).ready(function($) {

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });


/* ====================== ENQUIRE FORM ======================  */

function toggleFormVisibility() {
  const enquireForm = document.querySelector('.enquire-form');
  const backdrop = document.querySelector('.backdrop');
  if (window.location.hash === '#enquire') {
    enquireForm.classList.add('show');
    backdrop.classList.add('show');
    document.body.classList.add('noscroll'); // Add .noscroll to body
  } else {
    enquireForm.classList.remove('show');
    backdrop.classList.remove('show');
    document.body.classList.remove('noscroll'); // Remove .noscroll from body
  }
}

window.addEventListener('hashchange', toggleFormVisibility);
document.addEventListener('DOMContentLoaded', toggleFormVisibility);

document.querySelector('.enquire-form__close').addEventListener('click', function() {
  const enquireForm = document.querySelector('.enquire-form');
  const backdrop = document.querySelector('.backdrop');
  enquireForm.classList.remove('show');
  backdrop.classList.remove('show');
  window.location.hash = '';
  document.body.classList.remove('noscroll'); // Remove .noscroll from body
});

/* ====================== HEADER ======================  */

$('.header__mobile__btn').on('click', function() {
  $('.header').toggleClass('active');
  $('.nav').toggleClass('nav--open');
  $('body').toggleClass('noscroll');
});
  
/* ====================== INIT FUNCSTIONS ======================  */

/* ====================== WINDOW REISZE FUNCTION  ======================  */
  $(window).resize(function() {
  })
});
